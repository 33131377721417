import React from "react"
import { graphql } from "gatsby"
import marked from "marked"
import Container from "components/Container"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./EventPost.module.css"
import { Helmet } from "react-helmet"

// eslint-disable-next-line
export const EventPostPage = ({ data, ...props }) => (
  <div>
    <Helmet>
      <style type="text/css">
        {`
        pre {
          overflow-x: auto;
        }
      `}
      </style>
    </Helmet>
    <Container className={styles.posts}>
      <article className={styles.post}>
        <div className={styles.postimage}>
          {data.image ? (
              <Img
                loading="lazy"
                objectFit="cover"
                alt={data.title}
                title={data.title}
                fluid={data.image.childImageSharp.fluid}
              />
          ) : null}
          <div className={styles.posttags}>
            {(data.tags || []).filter(Boolean).map((tag) => (
              <span>{tag}</span>
            ))[0]}
          </div>
          <time
            dateTime={data.date}
            dangerouslySetInnerHTML={{ __html: marked(data.eventDate) }}
          />
              
        </div>
        <div className={styles.info}>
          <h2>{data.title}</h2>
          <h3>{data.subTitle}</h3>
          {data.description ? (
            <div
              className={styles.desc}
              dangerouslySetInnerHTML={{ __html: marked(data.description) }}
            />
          ) : null}
          {data.buttonText ? (
            <a className={styles.button} href={data.eventLink} target="_blank" rel="noreferrer">{data.buttonText}</a>
          ) : null}
        </div>
      </article>
    </Container>
  </div>
)

// eslint-disable-next-line
export const EventPostPageWrapper = (props) => (
  <EventPostPage
    {...props}
    data={props.data.markdownRemark.frontmatter}
  />
)

export default EventPostPageWrapper

export const pageQuery = graphql`
  query EventPostPage($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100 ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        title
        subTitle
        date
        tags
        description
        buttonText
        eventDate
        eventLink
        url
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
